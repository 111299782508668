import React from "react";

interface Props {
  content: string;
  url?: string;
  logoUrl?: string;
}

export const Button: React.FC<Props> = ({ content, url, logoUrl }) => {
  return (
    <button
      onClick={() => {
        if (url) {
          window.open(url);
        }
      }}
      className={`app-button ${!url ? " app-button--disabled" : ""}`}
    >
      <img
        style={{ width: 32, height: 32 }}
        alt="Quantaco Logo"
        src={logoUrl ?? "favicon.ico"}
      />
      <span>{content}</span>
    </button>
  );
};
