import "./App.css";
import { Button } from "./components/Button";

const apps = [
  { name: "SALESLINE", url: "https://salesline.quantaco.co/" },
  { name: "CASHUP", url: "https://cashup.quantaco.co/" },
  { name: "LEARNHOSPO", url: "https://learnhospo.com.au/login" },
  { name: "STARCOMPLY", url: "https://starcomply.com.au/login" },
  {
    name: "BENCHMARK",
    url: "https://system.netsuite.com/app/common/media/mediaitemfolders.nl?whence=",
  },
  {
    name: "FINPACK",
    url: "https://system.netsuite.com/app/common/media/mediaitemfolders.nl?whence=",
  },
  { name: "VALUER" },
  { name: "REBATE" },
  {
    name: "TEAMPAY",
    url: "https://system.netsuite.com/app/center/card.nl?sc=-29&whence=",
  },
  {
    name: "SUPPLYPAY",
    url: "https://system.netsuite.com/app/center/card.nl?sc=-29&whence=",
  },
  { name: "CAPITAL" },
  { name: "INSURANCE" },
  {
    name: "DEPUTY",
    url: "https://once.deputy.com/my/",
    logoUrl: "deputy-logo.png",
  },
  {
    name: "LIGHTYEAR",
    url: "https://app.lightyear.cloud/login",
    logoUrl: "light-year-logo.png",
  },
];

function App() {
  return (
    <div className="container">
      <div className="container__logo">
        <img alt="Quantaco Logo" src={"Quantaco.png"} />
      </div>
      <div className="container__apps">
        {apps.map(({ name, url, logoUrl }) => (
          <Button key={name} content={name} url={url} logoUrl={logoUrl} />
        ))}
      </div>
    </div>
  );
}

export default App;
